<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t(translationPath + 'title_liabilities') }}</h1>
      </b-row>
      <account-plan-submenu active="liabilities"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <account-plan-liabilities-header></account-plan-liabilities-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs" @activate-tab="onTabActivate">
            <LiabilityMenu>
              <template slot="account-plan-liabilities-categorization">
                <b-row no-gutters>
                  <b-col cols="8">
                    <div class="text-regular" v-html="$t(translationPath + 'liability.categorization.description')"></div>
                  </b-col>
                </b-row>
                <b-table class="spirecta-simple-table account-plan-table income-accounts-table" show-empty hover
                         responsive striped
                         stacked="md"
                         :items="accountsGrouped.liability"
                         :fields="fieldValues"
                         :tbodyTrClass="trClass"
                         :busy="busy"
                >
                  <template v-slot:table-busy>
                    <loader/>
                  </template>

                  <template v-slot:cell(title)="row">
                    <template v-if="row.item && row.item.is_account === true">
                      <span class="px-2 account_title">&ndash;</span>
                      <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'" class="account_title">
                        {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                      </b-link>
                      <b-badge v-if="row.item.status === 'suspended'" variant="warning" class="ml-2 cursor-help"
                               :title="$t(translationPath+'badge_suspended_help')">{{ $t( translationPath +
                        'badge_suspended' ) }}
                      </b-badge>
                    </template>
                    <template v-else-if="row.item">
                      <b-link :to="'/reports/performance/account-groups/'+row.item.id" class="account_group_title">
                        {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                      </b-link>
                    </template>
                    <template v-else>&nbsp;</template>
                  </template>

                  <template v-slot:cell(subtype)="row">
                    <template v-if="row.item && row.item.is_account">
                      <b-form-select
                        :disabled="isFormDisabled"
                        v-model="row.item.subtype"
                        :options="subtypeOptions"
                        @input="onChange"
                      ></b-form-select>
                    </template>
                    <template v-else-if="row.item && !row.item.is_account">
                      <div class="tr_heading">{{row.field.label}}</div>
                    </template>
                    <template v-else>&nbsp;</template>
                  </template>
                </b-table>

                <b-row class="mb-5 mt-4">
                  <b-col class="pt-4 pt-md-1">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="float-right ml-3 px-4 btn-save"
                      :disabled="saveDisabled"
                      @click="onSave"
                    >{{ $t('common.save') }}
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </LiabilityMenu>
          </b-tabs>
        </b-card>

      </div><!-- END: wrapper_white_with_border -->
    </div><!-- END: main-content-wrapper -->

    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onLeavePageConfirmed"
    ></confirm-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import AccountPlanTabMixin from './AccountPlanTabMixin'
import AssetLiabilitiesSubtypes from '@/mixins/assetLiabilitiesSubtypes'
import LiabilityMenu from './submenu/LiabilityMenu'
import AccountPlanLiabilitiesHeader from './headers/AccountPlanLiabilitiesHeader'

export default {
  name: 'AccountPlanLiabilitiesCategorization',
  mixins: [AccountPlanTabMixin, AssetLiabilitiesSubtypes],
  components: { LiabilityMenu, AccountPlanLiabilitiesHeader },
  data () {
    return {
      accountsFilter: ''
    }
  },
  computed: {
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title') },
        { key: 'subtype', label: this.$t(this.translationPath + 'subtype_save') }
      ]
    },
    subtypeOptions () {
      return this.getLiabilitySubtypesArray()
    }
  },
  methods: {
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }

      this.busy = true
      Promise.all([
        this.loadAccountGroupsWithData('liability'),
        this.loadAccountGroups('liability')
      ])
        .then(() => {
          this.busy = false
        })
    },
    onSave () {
      this.saveDisabled = true

      const accounts = this.accountsGrouped.liability.filter((item) => item.is_account ? item : null)
      const putData = {
        accounts: []
      }
      accounts.map((item) => {
        putData.accounts.push({ id: item.id, subtype: item.subtype })
      })

      axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/update-multiple`, putData)
        .then(() => {
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_liability_subtypes_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_liability_subtypes_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.hasUnsavedChanges = false
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_liability_subtypes_fail_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_liability_subtypes_title_failed').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.saveDisabled = false
        })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';
</style>
