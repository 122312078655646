export default {
  data () {
    return {
      translation_path_subtypes: 'common.asset_liabilities.subtypes.'
    }
  },
  methods: {
    getAssetSubtypesArray () {
      const options = [
        { value: 'cash_equivalent', text: this.$t(this.translation_path_subtypes + 'cash_equivalent') },
        { value: 'portfolio', text: this.$t(this.translation_path_subtypes + 'portfolio') },
        { value: 'real_asset', text: this.$t(this.translation_path_subtypes + 'real_asset') },
        { value: 'immaterial_asset', text: this.$t(this.translation_path_subtypes + 'immaterial_asset') },
        { value: 'material_asset', text: this.$t(this.translation_path_subtypes + 'material_asset') },
        { value: 'material_asset_house', text: this.$t(this.translation_path_subtypes + 'material_asset_house') },
        { value: 'alternative_investment', text: this.$t(this.translation_path_subtypes + 'alternative_investment') },
        { value: 'consumption_asset', text: this.$t(this.translation_path_subtypes + 'consumption_asset') },
        { value: 'own_company', text: this.$t(this.translation_path_subtypes + 'own_company') },
        { value: 'unlisted_company', text: this.$t(this.translation_path_subtypes + 'unlisted_company') },
        { value: 'pension', text: this.$t(this.translation_path_subtypes + 'pension') },
        { value: 'loan_to_others', text: this.$t(this.translation_path_subtypes + 'loan_to_others') },
        { value: 'other_asset_value_increase', text: this.$t(this.translation_path_subtypes + 'other_asset_value_increase') },
        { value: 'other_asset_cashflow', text: this.$t(this.translation_path_subtypes + 'other_asset_cashflow') },
        { value: 'other_asset', text: this.$t(this.translation_path_subtypes + 'other_asset') }
      ]

      options.sort((a, b) => a.text > b.text ? 1 : -1)
      options.unshift({ value: null, text: this.$t('common.please_select_an_option') })

      return options
    },
    getLiabilitySubtypesArray () {
      const options = [
        { value: 'consumption_credit', text: this.$t(this.translation_path_subtypes + 'consumption_credit') },
        { value: 'house_credit', text: this.$t(this.translation_path_subtypes + 'house_credit') },
        { value: 'student_loan', text: this.$t(this.translation_path_subtypes + 'student_loan') },
        { value: 'other_credit', text: this.$t(this.translation_path_subtypes + 'other_credit') },
        { value: 'credit_by_family_or_friend', text: this.$t(this.translation_path_subtypes + 'credit_by_family_or_friend') },
        { value: 'investment_credit', text: this.$t(this.translation_path_subtypes + 'investment_credit') },
        { value: 'credit_card', text: this.$t(this.translation_path_subtypes + 'credit_card') }
      ]

      options.sort((a, b) => a.text > b.text ? 1 : -1)
      options.unshift({ value: null, text: this.$t('common.please_select_an_option') })

      return options
    }
  }
}
